import './App.scss';
import LoginPage from './components/login/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './components/home/HomePage';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotFoundPage from './components/not-found/NotFoundPage';
import 'rsuite/dist/styles/rsuite-default.css';
import { devices } from './components/devices';
import Sidebar from './components/sidebar/Sidebar';
import LogoutPage from './components/logout/Logout';
import MapPage from './components/map/MapPage';
import UniverseBackground from './components/background/UniverseBackground';

function App() {
  return (
    <div className="App">
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <UniverseBackground />
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={LogoutPage} />
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/devices" component={devices.List} />
          <PrivateRoute exact path="/devices/create" component={devices.Create} />
          <PrivateRoute exact path="/devices/:id" component={devices.Show} />
          <PrivateRoute exact path="/map" component={MapPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
}

export default withRouter(App);
