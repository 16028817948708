import React from 'react';
import { Redirect } from 'react-router-dom';

const LogoutPage = (props) => {
    localStorage.clear()
    return <Redirect
        to={{
            pathname: '/login',
            state: { from: props.location }
        }} />
}
export default LogoutPage;