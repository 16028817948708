import React from 'react';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { Form, FormControl, FormGroup, ControlLabel, ButtonToolbar, Button, Schema, Loader } from 'rsuite';
import { requesters } from '../../requester';
import { Alert } from 'rsuite';
const { StringType } = Schema.Types;

const model = Schema.Model({
    email: StringType()
        .isEmail('Informe um e-mail válido.')
        .isRequired('Este campo é obrigatório.'),
    password: StringType()
        .minLength(8, 'Sua senha deve possuir pelo menos 8 caracteres.')
        .isRequired('Este campo é obrigatório.')
});

class LoginPage extends React.Component {

    state = {
        formValue: {},
        requesting: false
    }

    handleSubmit = () => {
        if (!this.form.check()) {
            console.error('Form Error');
            return;
        }
        this.setState({ requesting: true })
        requesters.UserRequester.login(this.state.formValue.email, this.state.formValue.password)
            .then((response) => {
                localStorage.setItem('token', response.token)
                return requesters.UserRequester.whoAmI()
            })
            .then((response) => {
                localStorage.setItem('userId', response)
                this.props.history.push('/');
            })
            .catch((err) => {
                Alert.error(err.networkError)
            })
            .finally(() => this.setState({ requesting: false }))
    }

    render() {
        const { formValue, requesting } = this.state
        return <div className="login-page-container">
            <div className="login-modal card">
                <img id="manna-logo" src="manna-juh-circle.png" alt="Manna Makers" />
                <div className="card-content">
                    {requesting ? <Loader center size="md" content="" backdrop /> : null}
                    <Form fluid
                        ref={ref => (this.form = ref)}
                        model={model}
                        onChange={formValue => {
                            this.setState({ formValue });
                        }}
                        formDefaultValue={formValue}
                        onSubmit={this.handleSubmit}
                    >
                        <FormGroup>
                            <ControlLabel>E-mail</ControlLabel>
                            <FormControl name="email" type="email" disabled={requesting} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Senha</ControlLabel>
                            <FormControl name="password" type="password" disabled={requesting} />
                        </FormGroup>
                        <ButtonToolbar>
                            <Button block type="submit" disabled={requesting}>Entrar</Button>
                        </ButtonToolbar>
                    </Form>
                </div>
            </div>
            <div className="planets">
                <img id="planeta1" src="planeta1.png" alt="planeta 1" />
                <img id="planeta4" src="planeta4.png" alt="planeta 4" />
                <img id="planeta6" src="planeta6.png" alt="planeta 6" />
                <img id="planeta8" src="planeta8.png" alt="planeta 8" />
                <img id="planeta9" src="planeta9.png" alt="planeta 9" />
            </div>

        </div>
    }
}

export default withRouter(LoginPage);