import React from 'react';
import { Button, ButtonToolbar, Modal } from 'rsuite';
import { CodeBlock, dracula } from "react-code-blocks";

class ModalSendData extends React.Component {

    state = {
        show: false,
        requesting: false
    }

    openModal = () => {
        this.setState({
            show: true
        })
    }

    closeModal = () => {
        this.setState({
            show: false
        })
    }

    render() {
        const { show } = this.state
        const { variable } = this.props
        return <div id="modal-create-variable-container">
            <Button onClick={this.openModal} appearance="ghost" block>Como salvar este dado</Button>
            <Modal show={show} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>Como enviar dados da variável {`#${variable.id} (${variable.name})`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>Instruções de upload a partir de um Arduino/ESP32</b></p>
                    <p>Baixe a biblioteca MannaJUH <a download href="/manna-juh-arduino.zip">clicando aqui</a> e instale na sua IDE do Arduino.</p>
                    <br/>
                    <CodeBlock
                        text={variable.type !== "audio" && variable.type !== "image" ? `#include <manna-lib.h>

char *ssid = "NOME DA SUA REDE WIFI";
char *pass = "SENHA DA SUA REDE WIFI";

void setup() {
    Serial.begin(9600);
    connectWiFi(ssid,pass);
}

void loop() {
    // Coloque aqui o código para capturar o dado
    ${variable.type === "string" ? "String" : "float"} data = ...; 

    // Código para salvar as informações na nuvem
    String timestamp = getDateTime();
    if(timestamp != "") {
        ${variable.type === "string" ? "sendStr" : "sendFloat"}(${variable.id}, timestamp, data);
    }

    // Esperar 10 segundos para a próxima coleta
    delay(10000);
}` : `// Funções da biblioteca em desenvolvimento`}
                        language={'cpp'}
                        showLineNumbers={true}
                        theme={dracula}
                    />
                    <br/>
                    <p><b>Instruções de upload a partir de um NodeMCU/ESP8266</b></p>
                    <p>Baixe a biblioteca MannaJUH ESP8266 <a download href="/manna-juh-esp8266.zip">clicando aqui</a> e instale na sua IDE do Arduino.</p>
                    <br />
                    <CodeBlock
                        text={variable.type !== "audio" && variable.type !== "image" ? `#include <esp8266-manna-lib.h>

char *ssid = "NOME DA SUA REDE WIFI";
char *pass = "SENHA DA SUA REDE WIFI";

void setup() {
    Serial.begin(9600);
    connectWiFi(ssid,pass);
}

void loop() {
    // Coloque aqui o código para capturar o dado
    ${variable.type === "string" ? "String" : "float"} data = ...; 

    // Código para salvar as informações na nuvem
    String timestamp = getDateTime();
    if(timestamp != "") {
        ${variable.type === "string" ? "sendStr" : "sendFloat"}(${variable.id}, timestamp, data);
    }

    // Esperar 10 segundos para a próxima coleta
    delay(10000);
}` : `// Funções da biblioteca em desenvolvimento`}
                        language={'cpp'}
                        showLineNumbers={true}
                        theme={dracula}
                    />
                    <br/>
                    <p><b>Instruções de upload a partir de um Raspberry</b></p>
                    <CodeBlock
                        text={`# Biblioteca em desenvolvimento`}
                        language={'python'}
                        showLineNumbers={true}
                        theme={dracula}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar style={{ float: 'right' }}>
                        <Button onClick={this.closeModal} appearance="subtle">
                            Fechar
                            </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default ModalSendData;