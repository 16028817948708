import React from 'react';
// import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from './../pro-sidebar';
import { withRouter } from 'react-router-dom';
import { Icon, Nav, Sidenav } from 'rsuite';
import './style.scss';

const ITEMS = {
    '1': {
        path: '/',
    },
    '2': {
        path: '/devices',
    },
    '3': {
        path: '/map',
    },
    '4': {
        path: '/logout',
    }
}

class Sidebar extends React.Component {

    constructor() {
        super();
        this.state = {
            expanded: true,
            activeKey: '1',
            show: false,
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }
    handleToggle() {
        this.setState({
            expanded: !this.state.expanded
        });
    }
    handleSelect(eventKey) {
        this.setState({
            activeKey: eventKey
        });
        this.props.history.push(ITEMS[eventKey].path)
    }

    updateSidenav = (pathname) => {
        if (pathname === "/login") {
            this.setState({ show: false })
        } else {
            this.setState({ show: true })
        }

    }

    componentDidMount() {
        this.mounted = true
        this.updateSidenav(this.props.history.location.pathname)
        this.props.history.listen(location => {
            if (this.mounted) {
                this.updateSidenav(location.pathname)
            }
        })
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { expanded, show } = this.state;

        return (
            show ? <div id="sidebar-container" style={{ maxWidth: 200, height: '100vh' }}>
                <Sidenav
                    appearance="inverse"
                    expanded={expanded}
                    defaultOpenKeys={['3', '4']}
                    activeKey={this.state.activeKey}
                    onSelect={this.handleSelect}
                    style={{ height: '100%' }}
                >
                    <Sidenav.Header>
                        <div onClick={this.handleToggle}><img src="/manna-juh-light.png" alt="manna makers" /></div>
                    </Sidenav.Header>
                    <Sidenav.Body>
                        <Nav>
                            <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />}>
                                Home
                            </Nav.Item>
                            <Nav.Item eventKey="2" icon={<Icon icon="sitemap" />}>
                                Dispositivos
                            </Nav.Item>
                            <Nav.Item eventKey="3" icon={<Icon icon="map" />}>
                                Mapa
                            </Nav.Item>
                            <Nav.Item eventKey="4" icon={<Icon icon="sign-out" />}>
                                Sair
                            </Nav.Item>
                            {/* <Dropdown
                                placement="rightStart"
                                eventKey="3"
                                title="Advanced"
                                icon={<Icon icon="magic" />}
                            >
                                <Dropdown.Item eventKey="3-1">Geo</Dropdown.Item>
                                <Dropdown.Item eventKey="3-2">Devices</Dropdown.Item>
                                <Dropdown.Item eventKey="3-3">Loyalty</Dropdown.Item>
                                <Dropdown.Item eventKey="3-4">Visit Depth</Dropdown.Item>
                            </Dropdown> */}
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div> : null
        );
    }
}

export default withRouter(Sidebar);