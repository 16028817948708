import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Button, Col, Divider, Grid, Icon, IconButton, Panel, Row } from 'rsuite';
import BarChart from "@rsuite/charts/lib/charts/BarChart";
import LineChart from "@rsuite/charts/lib/charts/LineChart";
import Lightbox from 'react-awesome-lightbox';
import { variables } from '.';
import './style.scss';
import "react-awesome-lightbox/build/style.css";
import { requesters } from '../../requester';

const SERVER_URI = requesters.URI


export class HeaderContentShow extends React.Component {

    render() {
        const { title, variable, device, toggleShowChart, showChart } = this.props
        return <Grid fluid>
            <Row>
                <Col sm={24}>
                    <h5>{title}</h5>
                </Col>
                <Col sm={12}>
                    <variables.ModalSendData variable={variable} device={device} />
                </Col>
                <Col sm={12}>
                    <Button block appearance="ghost" onClick={() => toggleShowChart(!showChart)}>{showChart ? 'Visualizar tabela' : 'Visualizar gráfico'}</Button>
                </Col>
            </Row>
        </Grid>
    }
}



export class ContentShow extends React.Component {

    state = {
        showChart: false,
        showImage: null
    }

    toggleShowChart = (show) => {
        this.setState({
            showChart: show
        })
    }

    getDataContent = (variable, data, i) => {
        let cols = []

        switch (variable.type) {
            case 'audio':
                // cols.push(<Col key={`${data.id}-0`} sm={12}>{`#${data.id}`}</Col>)
                cols.push(<Col key={`${data.id}-1`} sm={24}>{`${new Date(data.timestamp).toLocaleDateString('pt-BR')} ${new Date(data.timestamp).toLocaleTimeString('pt-BR')}`}</Col>)
                cols.push(<Col key={`${data.id}-2`} sm={24}><ReactAudioPlayer
                    style={{ width: "100%", margin: 'auto' }}
                    src={`${SERVER_URI}/files/download${data.data}`}
                    controls
                /></Col>)
                cols.push(<Col key={`${data.id}-3`} sm={24}><Divider /></Col>)
                break;
            case 'image':
                cols.push(<Col key={`${data.id}-1`} sm={6}>{this.state.showImage === i ? <Lightbox image={`${SERVER_URI}/files/download${data.data}`} onClose={() => this.setState({ showImage: null })} /> : <img src={`${SERVER_URI}/files/download${data.data}`} onClick={() => this.setState({ showImage: i })} />}</Col>)
                cols.push(<Col key={`${data.id}-2`} sm={12}>Coletado em:<br/>{`${new Date(data.timestamp).toLocaleDateString('pt-BR')} ${new Date(data.timestamp).toLocaleTimeString('pt-BR')}`}</Col>)
                cols.push(<Col key={`${data.id}-3`} sm={6}><a href={`${SERVER_URI}/files/download${data.data}`} download><IconButton icon={<Icon icon="download" />} /></a></Col>)
                break;
            case 'number':
            case 'string':
                // cols.push(<Col key={`${data.id}-0`} sm={4}>{`#${data.id}`}</Col>)
                cols.push(<Col key={`${data.id}-1`} sm={12}>{`${new Date(data.timestamp).toLocaleDateString('pt-BR')} ${new Date(data.timestamp).toLocaleTimeString('pt-BR')}`}</Col>)
                cols.push(<Col key={`${data.id}-2`} sm={12}>{`${variable.prefix ? variable.prefix : ""} ${data.data} ${variable.suffix ? variable.suffix : ""}`}</Col>)
            default:
                break;
        }
        return cols
    }

    getChart = (variable) => {
        const data = variable && variable.data ? variable.data : []

        switch (variable.type) {
            case 'audio':
            case 'image':
            case 'string':
                const groups = data.reduce((groups, d) => {
                    const date = d.timestamp.split('T')[0];
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(d);
                    return groups;
                }, {});
                const groupArrays = Object.keys(groups).map((date) => {
                    return [
                        new Date(date).toLocaleDateString('pt-BR'),
                        groups[date].length
                    ];
                });
                return <BarChart name="Dados Coletados" data={groupArrays} />
            case 'number':
                let chartData = data ? data.map(dt => {
                    return [`${new Date(dt.timestamp).toLocaleDateString('pt-BR')} ${new Date(dt.timestamp).toLocaleTimeString('pt-BR')}`, dt.data]
                }) : []
                return <LineChart name={variable.name} data={chartData} />
            default:
                return null;
        }
    }

    render() {
        const { showChart } = this.state
        const { variable, device, index } = this.props
        return <Panel defaultExpanded={index === 0}
            className="variable-content-show"
            header={<HeaderContentShow title={`Dado #${index} - ${variable.name}`}
                variable={variable}
                device={device}
                showChart={showChart}
                toggleShowChart={this.toggleShowChart} />}
            shaded >
            <div className="panel-body-content">
                {!showChart ? variable.data ? (
                    variable.data.reverse().map((data, i) => <Row key={i}>
                        {this.getDataContent(variable, data, i)}
                    </Row>)
                ) : <h6>Nenhum dado coletado</h6> : this.getChart(variable)}
            </div>
        </Panel>
    }
}