import { request } from './requester';

const login = (email, password) => {
    return request({
        type: "POST",
        action: '/login',
        model: 'users',
        data: {
            email,
            password
        }
    })
}


const whoAmI = () => {
    return request({
        type: "GET",
        action: '/whoAmI',
        model: 'users',
        auth: 'token',
        data: {}
    })
}


export const UserRequester = {
    login,
    whoAmI
}