import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Icon, IconButton, Loader, Panel, Schema } from 'rsuite';
import { requesters } from '../../requester';
import './style.scss';
const { StringType } = Schema.Types;

const model = Schema.Model({
    name: StringType()
        .isRequired('Este campo é obrigatório.'),
});

class Create extends React.Component {

    state = {
        formValue: {},
        requesting: false
    }

    handleSubmit = () => {
        if (!this.form.check()) {
            console.error('Form Error');
            return;
        }
        this.setState({ requesting: true })
        requesters.DeviceRequester.post({
            name: this.state.formValue.name,
            location: this.state.formValue.location,
            description: this.state.formValue.description,
            created_at: new Date().toISOString(),
            user_id: parseInt(localStorage.getItem('userId'))
        })
            .then((response) => {
                this.props.history.push(`/devices/${response.id}`)
            })
            .catch(console.error)
            .finally(() => this.setState({ requesting: false }))
    }

    render() {
        const { formValue, requesting } = this.state
        return <div className="devices-create-container">
            <Panel header="Criação de Dispositivo" shaded >
                {requesting ? <Loader center size="md" content="" backdrop /> : null}
                <Form fluid
                    ref={ref => (this.form = ref)}
                    model={model}
                    onChange={formValue => {
                        this.setState({ formValue });
                    }}
                    formDefaultValue={formValue}
                    onSubmit={this.handleSubmit}
                >
                    <FormGroup>
                        <ControlLabel>Nome</ControlLabel>
                        <FormControl name="name" disabled={requesting} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Localização</ControlLabel>
                        <FormControl name="location" disabled={requesting} />
                        <HelpBlock>Se o seu dispositivo possuir localização ele será exibido no mapa. <br />A localização deve ser da forma lat,long. <br />Exemplo: -23.409092, -51.935272</HelpBlock>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Descrição</ControlLabel>
                        <FormControl name="description" componentClass="textarea" rows={3} disabled={requesting} />
                    </FormGroup>
                    <ButtonToolbar>
                        <Button block type="submit" appearance="primary" disabled={requesting}>Salvar</Button>
                    </ButtonToolbar>
                </Form>
            </Panel>
        </div>
    }
}

export default withRouter(Create);