import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Panel, Table } from 'rsuite';
import { requesters } from '../../requester';
import './style.scss'

const { Column, HeaderCell, Cell, Pagination } = Table;


class List extends React.Component {

    state = {
        devices: [],
        requesting: false,
        page: 1,
        displayLength: 10,
        total: 0
    }

    getDevices = (page, displayLength) => {
        this.setState({ requesting: true, devices: [] })
        requesters.DeviceRequester.getUserDevices(localStorage.getItem('userId'), {
            filter: {
                limit: displayLength,
                skip: (page - 1) * displayLength
            }
        })
            .then((response) => {
                this.setState({ devices: [...response] })

            })
            .catch(console.error)
            .finally(() => this.setState({ requesting: false }))
    }

    countDevices = () => {
        // this.setState({ requesting: true })
        requesters.DeviceRequester.countUserDevices(1)
            .then((response) => {
                console.log(response)
                this.setState({ total: response })

            })
            .catch(console.error)
        // .finally(() => this.setState({ requesting: false }))
    }

    handleChangePage = (page) => {
        this.setState({
            page
        });
        this.getDevices(page, this.state.displayLength)
    }

    handleChangeLength = (length) => {
        this.setState({
            page: 1,
            displayLength: length
        });
        this.getDevices(1, length)
    }

    showDevice = (row) => {
        this.props.history.push(`/devices/${row.id}`)
    }

    componentDidMount() {
        this.getDevices(this.state.page, this.state.displayLength)
        this.countDevices()
    }

    render() {
        const { devices, requesting, page, displayLength, total } = this.state
        return <div className="devices-list-container">
            <Panel header={<Button appearance="primary" onClick={() => this.props.history.push('/devices/create')}>Novo Dispositivo</Button>} shaded >
                <Table height={420} data={devices} loading={requesting} onRowClick={this.showDevice} autoHeight>
                    <Column width={50} align="center" fixed>
                        <HeaderCell>#</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>

                    <Column width={100} fixed>
                        <HeaderCell>Nome</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>

                    <Column width={100}>
                        <HeaderCell>Descrição</HeaderCell>
                        <Cell dataKey="description" />
                    </Column>

                    <Column width={200}>
                        <HeaderCell>Localização</HeaderCell>
                        <Cell dataKey="location" />
                    </Column>
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>Criação</HeaderCell>
                        <Cell dataKey="created_at" />
                    </Column>
                </Table>

                <Pagination
                    lengthMenu={[
                        {
                            value: 2,
                            label: 2
                        },
                        {
                            value: 5,
                            label: 5
                        },
                        {
                            value: 10,
                            label: 10
                        },
                        {
                            value: 20,
                            label: 20
                        }
                    ]}
                    activePage={page}
                    displayLength={displayLength}
                    total={total}
                    onChangePage={this.handleChangePage}
                    onChangeLength={this.handleChangeLength}
                />
            </Panel>
        </div>
    }

}

export default withRouter(List);