import React from 'react';
import { Button, ButtonToolbar, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Loader, Modal, Schema, SelectPicker } from 'rsuite';
import { requesters } from '../../requester';
// import { requesters } from '../../requester';
const { StringType } = Schema.Types;

const model = Schema.Model({
    name: StringType()
        .isRequired('Este campo é obrigatório.'),
    type: StringType()
        .isRequired('Este campo é obrigatório.'),
});
class ModalCreate extends React.Component {

    state = {
        show: false,
        formValue: {},
        requesting: false
    }

    openModal = () => {
        this.setState({
            show: true
        })
    }

    closeModal = () => {
        this.setState({
            show: false
        })
    }

    handleSubmit = () => {
        console.log(this.state.formValue)
        if (!this.form.check()) {
            console.error('Form Error');
            return;
        }
        this.setState({ requesting: true })
        requesters.DeviceRequester.postDeviceVariable(this.props.device.id, {
            name: this.state.formValue.name,
            prefix: this.state.formValue.prefix,
            suffix: this.state.formValue.suffix,
            type: this.state.formValue.type,
            device_id: this.props.device.id
        })
            .then((response) => {
                console.log(response)
                this.closeModal()
                this.props.refresh()
            })
            .catch(console.error)
            .finally(() => this.setState({ requesting: false }))
    }

    render() {
        const { show, requesting, formValue } = this.state
        const { device } = this.props
        return <div id="modal-create-variable-container">
            <Button onClick={this.openModal} color="green" block>Coletar novo dado</Button>
            <Modal show={show} onHide={this.closeModal}>
                {requesting ? <Loader center size="md" content="" backdrop /> : null}
                <Modal.Header>
                    <Modal.Title>Coletar novo dado para dispositivo {`#${device.id} (${device.name})`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid
                        ref={ref => (this.form = ref)}
                        model={model}
                        onChange={formValue => {
                            this.setState({ formValue });
                        }}
                        formDefaultValue={formValue}
                        onSubmit={this.handleSubmit}
                    >
                        <FormGroup>
                            <ControlLabel>Nome</ControlLabel>
                            <FormControl name="name" disabled={requesting} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Prefixo</ControlLabel>
                            <FormControl name="prefix" disabled={requesting} />
                            <HelpBlock>O prefixo é um texto que virá antes do seu dado quando exibirmos para você. <br />Imagine que a sua variável seja um número e você queira exibir como R$[numero] reais, o prefixo é R$.<br />Variáveis do tipo Áudio e Imagem não possuem prefixo.</HelpBlock>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Sufixo</ControlLabel>
                            <FormControl name="suffix" disabled={requesting} />
                            <HelpBlock>O sufixo é um texto que virá depois do seu dado quando exibirmos para você. <br />Imagine que a sua variável seja um número e você queira exibir como R$[numero] reais, o sufixo é reais.<br />Variáveis do tipo Áudio e Imagem não possuem prefixo.</HelpBlock>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Tipo</ControlLabel>
                            <FormControl
                                block
                                name="type"
                                accepter={SelectPicker}
                                data={[
                                    { label: 'Áudio', value: 'audio' },
                                    { label: 'Imagem', value: 'image' },
                                    { label: 'Texto', value: 'string' },
                                    { label: 'Número', value: 'number' },
                                ]}
                                disabled={requesting}
                            />
                        </FormGroup>
                        <ButtonToolbar style={{ float: 'right' }}>
                            <Button type="submit" appearance="primary" disabled={requesting}>
                                Salvar
                            </Button>
                            <Button onClick={this.closeModal} appearance="subtle" disabled={requesting}>
                                Cancelar
                            </Button>
                        </ButtonToolbar>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    }
}

export default ModalCreate;