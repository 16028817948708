export const URI = "https://cloudapi.manna.team";

export const MODELS = {
    users: `${URI}/users`,
    devices: `${URI}/devices`,
}

function parseJSON(response) {
    if (response.ok && response.status !== 204) {
        return new Promise((resolve) => response.json()
            .then((json) => resolve({
                status: response.status,
                ok: response.ok,
                json,
            })));
    } else if (response.status === 204) {
        return Promise.resolve({ status: response.status, ok: response.ok });
    } else if (response.status === 401) {
        if (localStorage.getItem('token')) {
            window.location = '/logout'
        }
    }
    return new Promise((resolve, reject) => response.json()
        .then((json) => reject({
            status: response.status,
            message: json.error.message
        })));
}

function getQueryParams(data, type) {
    if (type === "GET" && data) {
        return `?${Object.keys(data).map(key => {
            if (Array.isArray(data[key])) {
                return data[key].map(elem => `${key}[]=${elem}`).join('&')
            } else if (typeof data[key] === 'object') {
                return `${key}=${JSON.stringify(data[key])}`
            } else {
                return `${key}=${data[key]}`
            }
        }).join('&')}`
    } else {
        return ''
    }
}

function getBody(data, type, contentType) {
    if (type === "GET") {
        return undefined
    } else if (contentType === "application/x-www-form-urlencoded; charset=UTF-8" && data) {
        let formData = []
        Object.keys(data).forEach(key => {
            formData.push(`${key}=${data[key]}`)
        })
        return formData.join("&");
    } else {
        return data ? JSON.stringify(data) : data
    }
}

function call({ type, action, model, auth, data, format, contentType, crossDomain }) {
    let headers = new Headers();
    if (auth) {
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    }
    headers.append('Content-Type', contentType)

    let init = {
        method: type,
        headers: headers,
        mode: 'cors',
        cache: 'default',
        body: getBody(data, type, contentType)
    };
    return new Promise((resolve, reject) => {
        fetch(`${model}${action}${getQueryParams(data, type)}`, init)
            .then(parseJSON)
            .then((response) => {
                if (response.ok) {
                    return resolve(response.json);
                }
                console.error(response)
                return reject(response);
            })
            .catch((error) => reject({
                status: error.status,
                networkError: error.message,
            }));
    })
}

export function request({ type = 'GET', action, model, auth, data = undefined, format = 'json', contentType = 'application/json', crossDomain = false }) {
    return call({ type, action, model: MODELS[model], auth, data, format, contentType })
}