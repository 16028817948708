import React from 'react';
import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
import { requesters } from '../../requester';
import { Button, Container, Drawer, Grid, Loader, Panel, PanelGroup } from 'rsuite';
import { variables } from '../variables';

class MapPage extends React.Component {

    state = {
        devices: [],
        requesting: false,
        showDrawer: false,
        selectedDevice: {},
        deviceData: []
    }

    closeDrawer = () => {
        this.setState({
            showDrawer: false,
            deviceData: [],
            selectedDevice: {}
        });
    }

    openDrawer = (device) => {
        // let data = []
        // for (let variable in device.variables) {
        //     data = [...data, ...device.variables[variable].data]
        // }
        console.log(device)
        this.setState({
            showDrawer: true,
            deviceData: device.variables,
            selectedDevice: device
        });
    }

    getDevices = () => {
        this.setState({ requesting: true, devices: [] })
        requesters.DeviceRequester.getUserDevices(localStorage.getItem('userId'), {
            filter: {
                "where": {
                    "and": [{ "location": { "neq": "" } }, { "location": { "neq": null } }]
                },
                "include": [
                    {
                        "relation": "variables",
                        "scope": {
                            "include": [{ "relation": "data" }]
                        }
                    }]
            }
        })
            .then((response) => {
                this.setState({ devices: [...response] })

            })
            .catch(console.error)
            .finally(() => this.setState({ requesting: false }))
    }

    componentDidMount() {
        this.getDevices()
    }

    render() {
        const { devices, requesting, showDrawer, deviceData, selectedDevice } = this.state
        return <Container>
            {requesting ? <Loader center size="md" content="" backdrop /> : null}
            {!requesting ? <Map center={[-23.406302, -51.934295]} zoom={15} >
                {devices.map(device => <Marker key={device.id} anchor={device.location.split(',').map(l => parseFloat(l))} payload={1} onClick={({ event, anchor, payload }) => { this.openDrawer(device) }} />)}
            </Map> : null}
            <Drawer
                size='sm'
                placement='right'
                show={showDrawer}
                onHide={this.closeDrawer}
            >
                <Drawer.Header>
                    <h3>{`#${selectedDevice.id} ${selectedDevice.name}`}</h3>
                    <h5>{`Criado em: ${new Date(selectedDevice.created_at).toLocaleDateString('pt-BR')} ${new Date(selectedDevice.created_at).toLocaleTimeString('pt-BR')}`}</h5>
                    <h5>{`Descrição: ${selectedDevice.description}`}</h5>
                </Drawer.Header>
                <Drawer.Body>
                    {deviceData.map((dd, i) => <PanelGroup accordion bordered>
                        <variables.ContentShow variable={dd} device={selectedDevice} index={i}/>
                    </PanelGroup>)}
                </Drawer.Body>
                <Drawer.Footer>
                    <Button appearance="subtle" onClick={this.closeDrawer}>Fechar</Button>
                </Drawer.Footer>
            </Drawer>
        </Container>
    }
}


export default MapPage;