import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar, Col, Content, ControlLabel, DatePicker, Form, FormControl, FormGroup, Grid, Header, Loader, Panel, Placeholder, Row, Schema, Container, HelpBlock } from 'rsuite';
import { requesters } from '../../requester';
import { variables } from '../variables';
import './style.scss';
const { StringType } = Schema.Types;
const { Paragraph } = Placeholder;

const model = Schema.Model({
    name: StringType()
        .isRequired('Este campo é obrigatório.'),
});

class Show extends React.Component {

    state = {
        formValue: {},
        id: null,
        requesting: false,
        loading: true,
        device: {}
    }

    handleSubmit = () => {
        if (!this.form.check()) {
            console.error('Form Error');
            return;
        }
        let formValue = this.state.formValue
        Object.keys(formValue).forEach((key) => {
            if (formValue[key] === null) {
                delete formValue[key]
            }
        })
        this.setState({ requesting: true })
        requesters.DeviceRequester.patch(this.state.id, {
            name: formValue.name,
            location: formValue.location,
            description: formValue.description,
        })
            .then((response) => {
                this.getDevice()
            })
            .catch(console.error)
            .finally(() => this.setState({ requesting: false }))
    }


    getDevice = () => {
        const device_id = this.props.history.location.pathname.split('/').pop()
        this.setState({ loading: true, id: device_id })
        requesters.DeviceRequester.getById(device_id, {
            filter: {
                "include": [
                    {
                        "relation": "variables",
                        "scope": {
                            "include": [{ "relation": "data" }]
                        }
                    }]
            }
        })
            .then((response) => {
                response.created_at = new Date(response.created_at)
                this.setState({ formValue: { ...response }, device: response })
            })
            .catch(console.error)
            .finally(() => this.setState({ loading: false }))
    }


    componentDidMount() {
        this.getDevice()
    }

    render() {
        const { formValue, requesting, loading, id, device } = this.state
        return <div className="devices-create-container">
            <Container>
                <Content>
                    <Grid fluid>
                        <Row>
                            <Col sm={24} md={24}>
                                <Panel header={`Dispositivo #${id}`} shaded >
                                    {requesting ? <Loader center size="md" content="" backdrop /> : null}
                                    {!loading ? <Form fluid
                                        ref={ref => (this.form = ref)}
                                        model={model}
                                        onChange={formValue => {
                                            this.setState({ formValue });
                                        }}
                                        formDefaultValue={formValue}
                                        onSubmit={this.handleSubmit}
                                    >
                                        <FormGroup>
                                            <ControlLabel>Nome</ControlLabel>
                                            <FormControl name="name" disabled={requesting} />
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Localização</ControlLabel>
                                            <FormControl name="location" disabled={requesting} />
                                            <HelpBlock>Se o seu dispositivo possuir localização ele será exibido no mapa. <br />A localização deve ser da forma lat,long. <br />Exemplo: -23.409092, -51.935272</HelpBlock>
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Descrição</ControlLabel>
                                            <FormControl name="description" componentClass="textarea" rows={3} disabled={requesting} />
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Criado em</ControlLabel>
                                            <FormControl type="date" name="created_at" readOnly disabled={requesting} format="DD/MM/YYYY HH:mm:ss" accepter={DatePicker} block />
                                        </FormGroup>
                                        <ButtonToolbar>
                                            <Grid fluid>
                                                <Row>
                                                    <Col sm={24} md={12} lg={8}>
                                                        <variables.ModalCreate device={device} refresh={this.getDevice} />
                                                    </Col>
                                                    <Col sm={24} md={6} lg={8}>
                                                        <Button block type="submit" appearance="primary" disabled={requesting}>Salvar Alterações</Button>
                                                    </Col>
                                                    <Col sm={24} md={6} lg={8}>
                                                        <Button block onClick={() => console.log('excluir device')} color="red" appearance="ghost" disabled={requesting}>Remover Dispositivo</Button>
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </ButtonToolbar>
                                    </Form> : <Paragraph />}
                                </Panel>
                            </Col>
                        </Row>
                        <Row id="device-show-variables-row">
                            {device && device.variables && device.variables.map((variable, i) => <Col sm={24} md={12} key={i}>
                                <variables.ContentShow variable={variable} device={device} index={i} />
                            </Col>)}
                        </Row>
                    </Grid>
                </Content>
            </Container>
        </div>
    }
}

export default withRouter(Show);