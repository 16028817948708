import React from 'react';
import { Panel } from 'rsuite';
import { requesters } from '../../requester';
import './style.scss';

class HomePage extends React.Component {

    componentDidMount() {
        requesters.UserRequester.whoAmI()
            .then(response => {
                console.log(response)
            })
            .catch(err => {
                console.error(err)
            })
    }

    render() {
        return <div className="home-container">
            <Panel shaded >
                <p><b>Bem-vindo(a) ao MannaJUH</b></p>
                <br />
                <p>A MannaJUH é uma plataforma de nuvem (cloud) que tem como objetivo facilitar a integração dos seus componentes eletrônicos à nuvem.</p>
                <p>Estamos trabalhando constantemente para melhorar a plataforma, e fazer com que você só precise se preocupar em programar os nós da sua rede!</p>
                <br />
                <p>No meu lateral você encontra 4 opções:</p>
                <ul>
                    <li><b>Home</b>: que te redireciona para esta página inicial</li>
                    <li><b>Dispositivos</b>: que permite que você cadastre os seus dispositivos e monitore as informações que ele coletou</li>
                    <li><b>Mapa</b>: que permite que você visualize os seus dispositivos que possuem posicionamento geográfico em um mapa</li>
                    <li><b>Sair</b>: que sai da sua conta e te redireciona para a tela de login</li>
                </ul>
                <br/>
                <p>Crie um dispositivo clicando em <b>"Dispositivos"</b> e comece a monitorar seus dados!</p>
            </Panel>
        </div>
    }
}

export default HomePage;