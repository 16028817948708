import { request } from './requester';

const get = (filter) => {
    return request({
        type: "GET",
        action: '',
        model: 'devices',
        auth: 'token',
        data: filter
    })
}

const getById = (id, filter) => {
    return request({
        type: "GET",
        action: `/${id}`,
        model: 'devices',
        auth: 'token',
        data: filter
    })
}

const getUserDevices = (id, filter) => {
    return request({
        type: "GET",
        action: `/${id}/devices`,
        model: 'users',
        auth: 'token',
        data: filter
    })
}

const countUserDevices = (id) => {
    return request({
        type: "GET",
        action: `/${id}/devices/count`,
        model: 'users',
        auth: 'token',
        data: {}
    })
}

const post = (data) => {
    return request({
        type: "POST",
        action: '',
        model: 'devices',
        auth: 'token',
        data
    })
}

const postDeviceVariable = (id, data) => {
    return request({
        type: "POST",
        action: `/${id}/variables`,
        model: 'devices',
        auth: 'token',
        data
    })
}

const patch = (id, data) => {
    return request({
        type: "PATCH",
        action: `/${id}`,
        model: 'devices',
        auth: 'token',
        data
    })
}


export const DeviceRequester = {
    get,
    getById,
    post,
    patch,
    getUserDevices,
    countUserDevices,
    postDeviceVariable
}